import React from "react";
import { useLocale } from "@insightfulscience/smart-react/i18";
import Layout from "@insightfulscience/atomic-react/Layout";
import PageMeta from "@insightfulscience/smart-react/PageMeta";
import Section from "@insightfulscience/atomic-react/Section";
import SectionTryForFree from "../../SectionTryForFree";
import HeroSection from "./HeroSection";
import FeatureSection from "./FeatureSection/index";
import SiteLayout from "../../SiteLayout";
import SequenceData from "./SequenceData";
import ExploreGeneious from "./ExploreGeneious";
import Testimonials from "./Testimonials";

const HomePage = () => {
	const [t] = useLocale("home-page");

	return (
		<SiteLayout isWrapper={false}>
			<Layout.Body>
				<PageMeta title={t("META.TITLE")} description={t("META.DESCRIPTION")}>
					<PageMeta.OGP
						locale={t("META.LOCALE")}
						type={t("META.TYPE")}
						title={t("META.TITLE")}
						description={t("META.DESCRIPTION")}
						url={t("META.URL")}
						siteName={t("META.SITE_NAME")}
						articlePublisher={t("META.ARTICLE_PUBLISHER")}
					/>
					<PageMeta.Canonical href={t("META.CANONICAL")} />
					<PageMeta.Twitter
						card={t("META.TWITTER_CARD")}
						title={t("META.TITLE")}
						description={t("META.DESCRIPTION")}
						site={t("META.TWITTER_SITE")}
						creator={t("META.TWITTER_CREATOR")}
					/>
				</PageMeta>
				<HeroSection />
				<FeatureSection />
				<SequenceData />
				<ExploreGeneious />
				<Section spacing={{ pb: 20 }}>
					<Testimonials />
				</Section>
				<SectionTryForFree />
			</Layout.Body>
		</SiteLayout>
	);
};

export default HomePage;
