import React from "react";
import SectionTryForFreeComponent from "@insightfulscience/atomic-react/SectionTryForFree";
import SectionTryForFreeLink from "@insightfulscience/atomic-react/SectionTryForFree/Link";
import { useLocale } from "@insightfulscience/smart-react/i18";

const SectionTryForFree = () => {
	const [t] = useLocale("section-try-for-free");
	return (
		<SectionTryForFreeComponent>
			<SectionTryForFreeComponent.Title>{t("TRY_FREE_TEXT")}</SectionTryForFreeComponent.Title>
			<SectionTryForFreeComponent.Action>
				<SectionTryForFreeLink
					href={t("URL")}
					spacing={{ sm: { mb: 4 }, md: { mr: 4, mb: 0 } }}
					utility={{ sm: { display: "block" }, md: { display: "inline-block" } }}
				>
					{t("TRY_FREE_BUTTON_TEXT")}
				</SectionTryForFreeLink>
				<SectionTryForFreeLink
					isOutline
					styleType="light"
					href={t("PRICING_URL")}
					utility={{ sm: { display: "block" }, md: { display: "inline-block" } }}
				>
					{t("PRICING")}
				</SectionTryForFreeLink>
			</SectionTryForFreeComponent.Action>
		</SectionTryForFreeComponent>
	);
};

export default SectionTryForFree;
