import React from "react";
import LandingSimpleSection from "@insightfulscience/atomic-react/LandingElement/SimpleSection";
import Feature from "@insightfulscience/atomic-react/Feature";
import FeatureMeta from "@insightfulscience/atomic-react/Feature/Meta";
import FeatureImage from "@insightfulscience/atomic-react/Feature/Image";
import Icon from "@insightfulscience/atomic-react/Icon";
import Button from "@insightfulscience/atomic-react/Button";
import { Col, Row } from "@insightfulscience/atomic-react/Grid";
import NextLink from "@insightfulscience/smart-react/NextLink";
import routes from "../../routes";

const feature2 = "/images/geneious/homepage/feature-2.png";
const feature3 = "/images/geneious/homepage/feature-3.png";

const SequenceData = () => {
	return (
		<>
			<LandingSimpleSection spacing={{ pb: 0 }} isBorderNone>
				<LandingSimpleSection.TitleV2>
					Unlock The Value of Your Sequence Data
				</LandingSimpleSection.TitleV2>
				<LandingSimpleSection.DescriptionV2
					sx={{ fontColor: "black--custom-1" }}
					spacing={{ mb: 16 }}
				>
					Geneious Prime and Geneious Biologics take away the pain of data management and
					computational complexity, allowing scientists to focus on their research.
				</LandingSimpleSection.DescriptionV2>
				<LandingSimpleSection.Body>
					<Feature>
						<FeatureImage md={8} src={feature2} />
						<FeatureMeta md={4}>
							<FeatureMeta.Title>
								Comprehensive Suite of Molecular Biology and Sequence Analysis Tools
							</FeatureMeta.Title>
							<FeatureMeta.Description sx={{ fontColor: "black--custom-1" }}>
								Geneious Prime is packed with fundamental bioinformatics tools, including assembly,
								alignment, tree building, cloning, primer design, and variant analysis for Sanger
								and NGS sequence data.{" "}
							</FeatureMeta.Description>
							<FeatureMeta.Action>
								<NextLink
									href="/features/prime#molecular-biology"
									sx={{ fontSize: "m", fontColor: "3" }}
								>
									Geneious Prime <Icon name="angle-right" color="3" />
								</NextLink>
							</FeatureMeta.Action>
						</FeatureMeta>
					</Feature>
					<Feature>
						<FeatureMeta md={4}>
							<FeatureMeta.Title>
								Transform Your Antibody Discovery Pipeline with Geneious Biologics
							</FeatureMeta.Title>
							<FeatureMeta.Description sx={{ fontColor: "black--custom-1" }}>
								Geneious Biologics provides advanced screening, analysis and visualizations for a
								wide range of antibody-like molecules, helping you to extract the maximum value from
								NGS, Single Cell, and Sanger datasets.
							</FeatureMeta.Description>
							<FeatureMeta.Action>
								<NextLink href="/features/biologics" sx={{ fontSize: "m", fontColor: "3" }}>
									Geneious Biologics <Icon name="angle-right" color="3" />
								</NextLink>
							</FeatureMeta.Action>
						</FeatureMeta>
						<FeatureImage md={8} src={feature3} />
					</Feature>
					<Row spacing={{ mt: 8 }}>
						<Col sx={{ textAlign: "center" }}>
							<Button.Link styleType="primary" size="s" href={routes.freeTrial}>
								Try for Free
							</Button.Link>
						</Col>
					</Row>
				</LandingSimpleSection.Body>
			</LandingSimpleSection>
		</>
	);
};

export default SequenceData;
