import Feature from "@insightfulscience/atomic-react/Feature";
import FeatureImage from "@insightfulscience/atomic-react/Feature/Image";
import FeatureMeta from "@insightfulscience/atomic-react/Feature/Meta";
import Paragraph from "@insightfulscience/atomic-react/Paragraph";
import Html from "@insightfulscience/smart-react/Html";
import React from "react";
import { useLocale } from "@insightfulscience/smart-react/i18";

const tabImg1 = "/images/geneious/homepage/easy-to-use.png";

export default () => {
	const [t] = useLocale("home-page");

	return (
		<Feature top className="mt-10">
			<FeatureMeta md={5}>
				<FeatureMeta.Title>{t("ADVANTAGES_CONTENT_1_TITLE")}</FeatureMeta.Title>
				<FeatureMeta.Description>
					<Paragraph sx={{ fontSize: "l" }}>
						<Html Tag="span">{t("ADVANTAGES_CONTENT_1_DESCRIPTION")}</Html>
					</Paragraph>
				</FeatureMeta.Description>
			</FeatureMeta>
			<FeatureImage md={7} shadow src={tabImg1} />
		</Feature>
	);
};
