import React from "react";
import Line from "@insightfulscience/atomic-react/Line";
import { Col, Container, Row } from "@insightfulscience/atomic-react/Grid";
import Box from "@insightfulscience/atomic-react/Box";
import Heading from "@insightfulscience/atomic-react/Heading";
import Icon from "@insightfulscience/atomic-react/Icon";
import Paragraph from "@insightfulscience/atomic-react/Paragraph";
import Image from "@insightfulscience/atomic-react/Image";
import NextLink from "@insightfulscience/smart-react/NextLink";
import Section from "@insightfulscience/atomic-react/Section";
import { useLocale } from "@insightfulscience/smart-react/i18";

const explore1 = "/images/geneious/homepage/explore-geneious-academy.png";
const explore2 = "/images/geneious/homepage/explore-2.png";
const explore3 = "/images/geneious/homepage/moth-final-2025.png";

const ExploreGeneious = () => {
	const [t] = useLocale("home-page");

	return (
		<Section spacing={{ pt: 20, pb: 10 }}>
			<Line spacing={{ mb: 12 }} />
			<Container>
				<Row>
					<Col>
						<Heading Tag="h2" sx={{ fontSize: "xl", textAlign: "center" }} spacing={{ mb: 8 }}>
							{t("EXPLORE_TITLE")}
						</Heading>
					</Col>
				</Row>
			</Container>
			<Container>
				<Row px py>
					<Col>
						<Box utility={{ bgColor: "dotmatics-4" }} spacing={{ p: 4 }}>
							<Row mx utility={{ align: { items: "center" } }}>
								<Col md={6}>
									<Image src={explore1} />
								</Col>
								<Col md={6}>
									<Heading Tag="h3" sx={{ fontSize: "m", fontWeight: "semi" }}>
										{t("EXPLORE_BOX_1_TITLE")}
									</Heading>
									<Paragraph>{t("EXPLORE_BOX_1_LIST.item_1")}</Paragraph>
									<Paragraph>{t("EXPLORE_BOX_1_LIST.item_1")}</Paragraph>
									<Box>
										<NextLink href="/academy" sx={{ fontColor: "3", fontSize: "m" }}>
											{t("EXPLORE_BOX_1_ACTION")} <Icon name="angle-right" color="3" />
										</NextLink>
									</Box>
								</Col>
							</Row>
						</Box>
					</Col>
				</Row>
				<Row px py>
					<Col md={6}>
						<Box
							utility={{ bgColor: "dotmatics-4" }}
							spacing={{ py: 5, px: 4 }}
							className="height-100"
						>
							<Row spacing={{ mb: 6 }}>
								<Col>
									<Image src={explore2} sizing={{ maxH: 28 }} />
								</Col>
							</Row>
							<Heading Tag="h3" sx={{ fontSize: "m", fontWeight: "semi" }}>
								{t("EXPLORE_BOX_2_TITLE")}
							</Heading>
							<Paragraph sx={{ fontSize: "m", fontColor: "black--custom-1" }}>
								{t("EXPLORE_BOX_2_DESCRIPTION")}
							</Paragraph>
							<Box>
								<NextLink
									href="/features/antibody-sequence-analysis"
									sx={{ fontColor: "3", fontSize: "m" }}
								>
									{t("EXPLORE_BOX_2_ACTION")} <Icon name="angle-right" color="3" />
								</NextLink>
							</Box>
						</Box>
					</Col>
					<Col md={6}>
						<Box
							utility={{ bgColor: "dotmatics-4" }}
							spacing={{ py: 5, px: 4 }}
							className="height-100"
						>
							<Row spacing={{ mb: 6 }}>
								<Col>
									<Image src={explore3} sizing={{ maxH: 28 }} />
								</Col>
							</Row>
							<Heading Tag="h3" sx={{ fontSize: "m", fontWeight: "semi" }}>
								{t("EXPLORE_BOX_3_TITLE")}
							</Heading>
							<Paragraph sx={{ fontSize: "m", fontColor: "black--custom-1" }}>
								{t("EXPLORE_BOX_3_DESCRIPTION")}
							</Paragraph>
							<Box>
								<NextLink href="/updates" sx={{ fontColor: "3", fontSize: "m" }}>
									{t("EXPLORE_BOX_3_ACTION")} <Icon name="angle-right" color="3" />
								</NextLink>
							</Box>
						</Box>
					</Col>
				</Row>
			</Container>
		</Section>
	);
};

export default ExploreGeneious;
