import React from "react";
import { Container } from "@insightfulscience/atomic-react/Grid";
import Testimonial from "@insightfulscience/atomic-react/Testimonial";
import {
	TestimonialColInfo,
	TestimonialColLogo,
} from "@insightfulscience/atomic-react/Testimonial/Grid";
import TestimonialBlockQuote from "@insightfulscience/atomic-react/Testimonial/BlockQuote";
import Block from "@insightfulscience/atomic-react/Block";
import TestimonialTabs from "@insightfulscience/atomic-react/Testimonial/Tabs";
import TestimonialTabsItem from "@insightfulscience/atomic-react/Testimonial/Tab";
import Image from "@insightfulscience/atomic-react/Image";
import useTestimonial from "@insightfulscience/atomic-react/Testimonial/hooks/useTestimonial";

const customerLogo1 = "/images/common/companies/novartis-logo.svg";
const customerLogo2 = "/images/common/companies/university-of-washington-logo.svg";
const customerLogo3 = "/images/common/companies/oxford-university-logo.svg";
const customerLogo4 = "/images/common/companies/uzh-logo.svg";
const customerLogo5 = "/images/common/companies/harvard-university-logo.svg";
const customerLogo6 = "/images/common/companies/lifearc-logo.svg";

const items = [
	{
		colorLogo: customerLogo1,
		image: null,
		author: "Charles Moore",
		link: "https://www.labarchives.com/blog/a-tool-for-the-sciences-and-humanities",
		title:
			"“Geneious is an absolutely beautiful package to work with. We love it and we are telling everyone about it.”",
		company: "Novartis Pharma AG, Switzerland",
		text: "",
	},
	{
		colorLogo: customerLogo2,
		image: null,
		author: "Dr. Monique Stanfel",
		link: "",
		title:
			"“This program is wonderful! I was desperately looking for a software program that would make analyzing my sequencing data less painful. Thank you for developing a quality, user-friendly program. The interface is great!”",
		company: "University of Washington",
		text: "",
	},

	{
		colorLogo: customerLogo3,
		image: null,
		author: "Dr. Gerton Lunter",
		link: "",
		title:
			"“What was impressive and powerful was that Geneious went from an unknown sequence to a known 3D protein structure quickly and intuitively in a single application.”",
		company: "Oxford University",
		text: "",
	},

	{
		colorLogo: customerLogo4,
		author: "Jean-Charles Paterna",
		image: null,
		link: "",
		title:
			"“Geneious cannot be overestimated! A (work) life without Geneious is no longer possible – it feels dark, cold and hostile…”",
		company: "Viral Vector Facility (VVF) of the Neuroscience Center Zurich (ZNZ) ",
		text: "",
	},
	{
		colorLogo: customerLogo5,
		author: "Cole Trapnell",
		image: null,
		link: "",
		title:
			"“Geneious has done a great deal for our lab, and I think it adds incredible value for labs with a mixture of wet and dry folks or those doing lots of cloning like ours.”",
		company: "Harvard University",
		text: "",
	},
	{
		colorLogo: customerLogo6,
		author: "Dr. Arkadiusz Oleksy",
		image: null,
		link: "",
		title:
			"“Geneious Biologics is a true one-stop destination for any type of antibody sequencing data analysis.”",
		company: "LifeArc",
		text: "",
	},
];

const Testimonials = () => {
	const [active, activeIndex, setActiveIndex] = useTestimonial(items);
	return (
		<Container>
			<Testimonial spacing={{ mb: 4, sm: { px: 2 } }}>
				<TestimonialColInfo>
					<TestimonialBlockQuote author={active.author} company={active.company}>
						{active.title}
					</TestimonialBlockQuote>
				</TestimonialColInfo>
				<TestimonialColLogo>
					<Image src={active.colorLogo} sizing={{ w: "full", maxW: 80 }} />
				</TestimonialColLogo>
			</Testimonial>
			<Block spacing={{ mt: 6 }}>
				<TestimonialTabs>
					{items.map(({ colorLogo }, index) => (
						<TestimonialTabsItem
							key={colorLogo}
							logoImg={colorLogo}
							invert="white"
							active={activeIndex === index}
							onClick={() => setActiveIndex(index)}
						/>
					))}
				</TestimonialTabs>
			</Block>
		</Container>
	);
};

export default Testimonials;
